/**
 * Initialize All Scripts
 */

 var widthvw = jQuery(window).width();


jQuery(document).ready(
    function () {

        $('#search_text').on('click',
            function (e) {
                if (e.altKey) {
                    alert('clicked')
                }
            }
        );

        var width = $(window).width();
        var height = $(window).height();

        var slider_widget = jQuery('.slider-widget');
        if (slider_widget) {
            slider_widget.owlCarousel({
                items:1,
                loop:true,
                margin:10,
                autoplay:true,
                autoplayTimeout:3000,
                autoplayHoverPause:false,
            });
        }

        jQuery('.owl-carousel-achat-vins').owlCarousel(
            {
                loop : true,
                margin : 0,
                nav : true,
                items : 1,
                dots : false,
                navText : ['<i class="icon-angle-left"></i>', '<i class="icon-angle-right"></i>'],
                responsive : {
                    // breakpoint from 0 up
                    0 : {
                        items : 1,
                        dots : true
                    },

                    // breakpoint from 768 up
                    768 : {
                        items : 1
                    }
                }
            }
        );

        jQuery('.connaitre-choisir-vin .list-items .item a').dotdotdot();
        jQuery('.connaitre-choisir-vin .vin-boire-avec .list-items .item a').dotdotdot();


        jQuery('.slider-incontournables').owlCarousel(
            {
                loop : true,
                margin : 0,
                nav : true,
                items : 1,
                dots : false,
                navText : ['<i class="icon-angle-left"></i>', '<i class="icon-angle-right"></i>']
            }
        );
        jQuery('.slide-offer').owlCarousel(
            {
                loop : true,
                margin : 0,
                nav : true,
                items : 1,
                dots : false,
                navText : ['<i class="icon-angle-left"></i>', '<i class="icon-angle-right"></i>']
            }
        );
        jQuery('.slider-vignerons').owlCarousel(
            {
                loop : true,
                margin : 0,
                nav : true,
                items : 1,
                dots : false,
                navText : ['<i class="icon-angle-left"></i>', '<i class="icon-angle-right"></i>']
            }
        );

        if(jQuery(".banner-slider").length > 0) {
            jQuery('.banner-slider .bg-img-slider').owlCarousel(
                {
                    autoplay:true,
                    //autoplayTimeout:1000,
                    autoplayHoverPause:true,
                    loop : true,
                    margin : 0,
                    nav : true,
                    items : 1,
                    dots : true,
                    navText : ['<i class="icon-angle-left"></i>', '<i class="icon-angle-right"></i>']
                }
            );
        }


        if(jQuery(".tab-ancre").length > 0) {
            jQuery('.tab-ancre a[href^="#"]').removeClass("active");
            jQuery('.tab-ancre a[href^="#"]:eq(0)').addClass("active");

            jQuery('.tab-ancre a').on("click", function(){
                var stickeHeader = jQuery(".bg-menu").height();
                var the_id = jQuery(this).attr("href");
                jQuery('.tab-ancre a[href^="#"]').removeClass("active");
                jQuery(this).addClass("active");

                jQuery('html, body').animate({
                    scrollTop: jQuery(the_id).offset().top - stickeHeader - 20
                }, 200, "linear");
                return false;
            });
        }


        if (width > 990 && width < 1367) {
            // menu
            //$('header .menulhg .minimal-menu > ul > li > .menu-wrapper').slideUp();
            //$('header .menulhg .minimal-menu > ul > li').removeClass("active");
            //$('header .menulhg .minimal-menu > ul > li.submenu > a').click(
            //    function (e) {
            //        e.preventDefault();
            //        var $this = $(this).parent("li").find(".menu-wrapper");
            //        var $thissub = $(this).parent("li");
            //        $("header .menulhg .minimal-menu > ul > li > .menu-wrapper").not($this).slideUp();
            //        $('header .menulhg .minimal-menu > ul > li').not($thissub).removeClass("active");
            //        $this.slideToggle("slow");
            //        $thissub.toggleClass("active");
            //    }
            //);
        }
        if (width > 990) {
            if ($(this).scrollTop() > 102) {
                $('.bg-menu').addClass('header-fixed');
                $('body').addClass('sticky-header');
            } else {
                $('.bg-menu').removeClass('header-fixed');
                $('body').removeClass('sticky-header');
            }

            $(window).scroll(
                function () {

                    if ($(this).scrollTop() > 102) {
                        $('.bg-menu').addClass('header-fixed');
                        $('body').addClass('sticky-header');
                    } else {
                        $('.bg-menu').removeClass('header-fixed');
                        $('body').removeClass('sticky-header');
                    }

                }
            );
        }
        if (width < 1043){

            if ($(this).scrollTop() > 0) {
                $('.bg-menu').addClass('header-fixed');
                $('body').addClass('sticky-header');
            } else {
                $('.bg-menu').removeClass('header-fixed');
                $('body').removeClass('sticky-header');
            }

            $(window).scroll(
                function () {

                    if ($(this).scrollTop() > 0) {
                        $('.bg-menu').addClass('header-fixed');
                        $('body').addClass('sticky-header');
                    } else {
                        $('.bg-menu').removeClass('header-fixed');
                        $('body').removeClass('sticky-header');
                    }


                }
            );

            // menu
            $('header .menulhg .minimal-menu > ul > li > .menu-wrapper').slideUp();
            $('header .menulhg .minimal-menu > ul > li').removeClass("active");
            $("header .menulhg .minimal-menu ul li.megamenu.tout-vin .menu-wrapper .col-a-half:first-child > .block-submenu > ul").show();
            $('header .menulhg .minimal-menu > ul > li > .show-submenu').on('click',
                function (e) {
                    e.preventDefault();
                    var $this = $(this).next(".menu-wrapper");
                    var $thissub = $(this).parent("li");
                    $("header .menulhg .minimal-menu ul li.megamenu.tout-vin .menu-wrapper .col-a-half:first-child > .block-submenu > ul").show();
                    $("header .menulhg .minimal-menu > ul > li > .menu-wrapper").not($this).slideUp();
                    $('header .menulhg .minimal-menu > ul > li').not($thissub).removeClass("active");
                    $this.slideToggle("slow");
                    $thissub.toggleClass("active");
                }
            );

            // sub menu
            $('header .menulhg .minimal-menu > ul > li > .menu-wrapper .block-submenu ul').slideUp();
            $('header .menulhg .minimal-menu > ul > li > .menu-wrapper .block-submenu .btn').slideUp();
            $("header .menulhg .minimal-menu > ul > li > .menu-wrapper .block-submenu .font-sec").removeClass("active");

            $('header .menulhg .minimal-menu > ul > li > .menu-wrapper .block-submenu .show-submenu').on('click',
                function (e) {
                    e.preventDefault();
                    var $this = $(this).next("ul");
                    var $thisbtn = $(this).parent(".block-submenu").find(".btn");
                    var $thisfs = $(this).parent(".block-submenu").find(".font-sec");
                    $("header .menulhg .minimal-menu > ul > li > .menu-wrapper .block-submenu ul").not($this).slideUp();
                    $("header .menulhg .minimal-menu > ul > li > .menu-wrapper .block-submenu .btn").not($thisbtn).slideUp();
                    $("header .menulhg .minimal-menu > ul > li > .menu-wrapper .block-submenu .font-sec").not($thisfs).removeClass("active");
                    $this.slideToggle("slow");
                    $thisbtn.slideToggle("slow");
                    $thisfs.toggleClass("active");
                }
            );

            $(".from-search #search_text").on('click',function() {
                $('html, body').animate({
                    scrollTop: $(this).offset().top - 160
                }, 500);
            });

        }
        if (width <= 767 && width > 450){
            $('.bg-menu').removeClass('header-fixed2');
            $(".from-search #search_text").on('click', function(e) {
                $('.bg-menu').addClass('header-fixed2');
                $('html, body').animate({
                    scrollTop: $(this).offset().top - 20
                }, 500);

            });
            $(document).on('click', function(e) {
                if ($(e.target).is('.from-search #search_text') === false) {
                    $('.bg-menu').removeClass('header-fixed2');
                }
            });

        }
        if (width > 767) {
            var heightnouveau = $('.owl-carousel-achat-vins.owl-carousel .owl-item img').height();
            jQuery('.section-home-achat-vins .top-achat-vins .nouveau').css('height', heightnouveau);

        }
        if (width < 768) {
            jQuery('.section-home-achat-vins .bottom-achat-vins ul').owlCarousel(
                {
                    loop : true,
                    margin : 10,
                    nav : true,
                    items : 1,
                    dots : true,
                    navText : ['<i class="icon-angle-left"></i>', '<i class="icon-angle-right"></i>']
                }
            );

            jQuery('.connaitre-choisir-vin .con-choisir .list-items').owlCarousel(
                {
                    loop : true,
                    margin : 0,
                    nav : true,
                    items : 1,
                    dots : true,
                    navText : ['<i class="icon-angle-left"></i>', '<i class="icon-angle-right"></i>']
                }
            );

            jQuery('.fiche .panels .blocks').owlCarousel(
                {
                    loop : true,
                    margin : 0,
                    nav : true,
                    items : 1,
                    dots : true,
                    autoHeight : true,
                    navText : ['<i class="icon-angle-left"></i>', '<i class="icon-angle-right"></i>']
                }
            );

            jQuery('.fiche .quel-plat-avec-vin .list-items').owlCarousel(
                {
                    loop : true,
                    margin : 16,
                    nav : true,
                    items : 2,
                    dots : false,
                    navText : ['<i class="icon-angle-left"></i>', '<i class="icon-angle-right"></i>']
                }
            );

            $('.connaitre-choisir-vin .appellations .list-menus .item-menu ul').slideUp();
            $('.connaitre-choisir-vin .appellations .list-menus .item-menu h3').on('click',
                function (e) {
                    e.preventDefault();
                    var $this = $(this).next("ul");
                    $(".connaitre-choisir-vin .appellations .list-menus .item-menu ul").not($this).slideUp();
                    $this.slideToggle("slow");
                    $(this).toggleClass("active");
                }
            );

            $('.section-footer .footer-middle .item-menu-bt ul').slideUp();
            $('.section-footer .footer-middle .item-menu-bt h4').on('click',
                function (e) {
                    e.preventDefault();
                    var $this = $(this).next("ul");
                    $(".section-footer .footer-middle .item-menu-bt ul").not($this).slideUp();
                    $this.slideToggle("slow");
                    $(this).toggleClass("active");
                    $(this).parent(".item-menu-bt").toggleClass("active");
                }
            );

            $(window).scroll(
                function () {
                    if ($(window).scrollTop() > 50) {
                        $('.recherche .mobile-search-block').addClass('search-fixed');
                        if ($('.recherche .mobile-search-block').hasClass('active')) {
                            $('.recherche .mobile-search-block').removeClass('search-fixed');
                        }

                    } else {
                        $('.recherche .mobile-search-block').removeClass('search-fixed');
                    }

                }
            );
            $(".recherche .mobile-search-block").on('click',
                function () {
                    $("html, body").animate({ scrollTop : 0 }, 0);
                    return false;
                }
            );


        }
        //var bd = $('<div class="filter-backdrop"></div>');


        //collapse
        $(".block-collapse .head").on('click',
            function () {
                $(this).parent(".block-collapse").toggleClass("active");
            }
        );

        $(".mobile-search-block").on('click',
            function () {
                $(".mobile-search-block-collapse").toggleClass("hidden-xs");
            }
        );


        $(".content-des").shorten(
            {
                "showChars" : 550
            }
        );

    }
);


(function ($) {
    $(window).on(
        "load", function () {

            jQuery(".block-collapse .content").mCustomScrollbar(
                {
                    theme : "g-scroller",
                    axis : "y", // horizontal scrollbar
                    autoExpandHorizontalScroll : true
                }
            );

            jQuery('.connaitre-choisir-vin .list-items .item a').dotdotdot();


        }
    );

    $.fn.shorten = function (settings) {

        var config = {
            showChars : 100,
            ellipsesText : "",
            moreText : "[...]",
            lessText : "[x]"
        };

        if (settings) {
            $.extend(config, settings);
        }

        $(document).off("click", '.morelink');

        $(document).on(
            {
                click : function () {

                    var $this = $(this);
                    if ($this.hasClass('less')) {
                        $this.removeClass('less');
                        $this.html(config.moreText);
                    } else {
                        $this.addClass('less');
                        $this.html(config.lessText);
                    }
                    $this.parent().prev().toggle();
                    $this.prev().toggle();
                    return false;
                }
            }, '.morelink'
        );

        return this.each(
            function () {
                var $this = $(this);
                if ($this.hasClass("shortened")) {
                    return;
                }

                $this.addClass("shortened");
                var content = $this.html();
                if (content.length > config.showChars) {
                    var c = content.substr(0, config.showChars);
                    var h = content.substr(config.showChars, content.length - config.showChars);
                    var html = c + '<span class="moreellipses">' + config.ellipsesText + ' </span><span class="morecontent"><span>' + h + '</span> <a href="#" class="morelink">' + config.moreText + '</a></span>';
                    $this.html(html);
                    $(".morecontent span").hide();
                }
            }
        );

    };

})(jQuery);